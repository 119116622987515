import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/greg/Desktop/projects/portfolio/src/components/page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This cyber security firm was where I spent most of my early career and a pretty
significant chunk of my life. After almost taking a permanent role at
`}<a parentName="p" {...{
        "href": "/fitch"
      }}>{`fitch ratings`}</a>{`, I bumped into one of my highschool friends who had gone to
the University of Pittsburgh. He had gotten into cyber security and was one of
the first developers at this small company. And as chance might have it, they
needed a ui/ux engineer...`}</p>
    <h2>{`pittsburgh`}</h2>
    <p>{`Pittsburgh, if you aren't already aware, is a `}<a parentName="p" {...{
        "href": "https://www.wesa.fm/post/pittsburgh-earning-reputation-cyber-security-hub#stream/0"
      }}>{`bit`}</a>{` of a `}<a parentName="p" {...{
        "href": "https://pghtechfuse.com/uncategorized/cybersecurity-report/"
      }}>{`hub`}</a>{` for
`}<a parentName="p" {...{
        "href": "https://www.post-gazette.com/business/tech-news/2015/07/19/How-Pittsburgh-became-a-global-center-for-fighting-cybercrime/stories/201507190104"
      }}>{`cyber security`}</a>{`. Organizations like CERT and the NCFTA make it a primary
location for conferences and other events.`}</p>
    <p>{`I moved here early in 2015 to start the new job. It was a terrible time to move
as the weather was in the single digits for the first week I was there. After a
couple weeks things smoothed out a bit and paying less than half of what I
would've in new york for rent helped sweeten the deal.`}</p>
    <h2>{`products`}</h2>
    <p>{`When I first arrived, Qintel was predominantly a services based company. Their
analysts worked with various organizations to investigate a spectrum of cyber
crime activity. They utilized a wide variety of tools in the space to uncover
and map out abusive schemes, identifying the actors and technologies involved.`}</p>
    <p>{`One of these tools was built in-house and offered users the ability to search
through a highly targeted set of forum data. My initial assignment was to build
a graphical user interface around the ReSTful API and CLI tools they already
had already started constructing. This work made the tool more accessible for
non-programmers and we moved on to add more features and integrate a lot more
data into the platform.`}</p>
    <p>{`We were up to our fourth major iteration of `}<a parentName="p" {...{
        "href": "/crosslink"
      }}>{`crosslink`}</a>{` when I left and have
expanded their offerings to include a few tangential products. You can view
overviews of them all `}<a parentName="p" {...{
        "href": "https://www.qintel.com/products"
      }}>{`here`}</a>{`.`}</p>
    <h2>{`culture`}</h2>
    <p>{`One of the most interesting aspects of being here has been watching and
participating in the company's growth. When I first I came on, there were only
about 15 or so in-house employees. When I left in early 2020, they had grown to
50 employees.`}</p>
    <p>{`Almost all the initial employees were still on and despite the growth and
office moves, the culture had remained the same. The company was relatively
flat and virtually every employee who was on board loved what they were working
on and actively participated in discussions of what was coming next.`}</p>
    <p>{`That's not to say we didn't have growing pains though. Going from 15 to 50
presents a number of challenges, e.g. keeping things focused and finding a
balance between efficient processes and too much bureaucracy.`}</p>
    <h2>{`a tough farewell`}</h2>
    <p>{`While I enjoyed my work at Q and my time in Pittsburgh, I had moved back to NYC
in 2018 with the birth of our first child. Working remotely got old after a few
years and my plan coming out of college was initially to bounce around a little
more. I wanted to get experience in other industries and learn from new teams.`}</p>
    <p>{`At the time of leaving, I was the technical lead for `}<a parentName="p" {...{
        "href": "/crosslink"
      }}>{`crosslink`}</a>{` and ui/ux
lead for the company. It was tough saying goodbye to my team and the rest of
the ballbusters I worked with on a daily basis, but I knew they'd continue to
build great stuff without me. The five years I spent there were a hell of ride
and I wish everyone there nothing but the best.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      